// Headless UI
import { Disclosure, Transition } from '@headlessui/react';
// Next image
import Image from 'next/image';
// Use Router next navigation
import { useRouter } from 'next/navigation';
// React
import React, { useMemo } from 'react';
// Icon
import { FiChevronRight, FiMinus, FiPlus } from 'react-icons/fi';
import { IconType } from 'react-icons/lib';

// Upsell kit
import upsell, {
	CartItem,
	calculateCartItem,
	convertVariations,
	getExtrasText,
	toCurrency,
} from '@monorepo/upsell-kit';

import { PRODUCTS_WITH_PRE_ORDER } from '../../constants/products';
import useResize from '../../lib/hooks/useResize';
import { EditOrderIcon, TrashIcon } from '../_shared/Icons';

// Cart Layout Item props
interface Props {
	item: CartItem;
	handleClose?: () => void;
}

const CartCard = ({ item, handleClose }: Props) => {
	// Get the extras array that has text information about the extras
	const extras = item.output ? getExtrasText(item.output) : null;

	// Price each Item
	const price = calculateCartItem(item);

	const cartQuantity = upsell.cart.useQuantity();

	// Function for delete the item directly
	const handleDelete = () => {
		if (cartQuantity.unique === 1) {
			window.closeCart();
		}
		upsell.cart.actions.delete(item.ref);
	};

	// Function for decrease the quantity of the item if the length is more than 1, because delete has to be runned through the trash icon
	const handleDecrease = () => {
		if (item.quantity > 1) {
			upsell.cart.actions.update(item.ref, { ...item, quantity: item.quantity - 1 }, true);
		}
	};

	// Handle increase quantity
	const handleIncrease = () => {
		upsell.cart.actions.update(item.ref, { ...item, quantity: item.quantity + 1 }, true);
	};

	// If user want to customize or edit its item it will be closed the modal and redirect user to the edit page
	const router = useRouter();
	const handleEdit = () => {
		if (handleClose) {
			handleClose();
		}

		if (item.outputDeal) {
			router.push(`/deals/${item.outputDeal._data._id}?edit=${item.ref}`);
		} else {
			router.push(`/products/${item.output?._data._id}?edit=${item.ref}`);
		}
	};

	const getItemName = () => {
		if (item.outputDeal) {
			return item.outputDeal._data.name;
		}

		if (item.output && item.outputSplit) {
			return `Split product`;
		}

		return item.output?._data.name;
	};

	const variation = useMemo(() => {
		if (
			!item.output ||
			!item.output.variables ||
			!item.output._data ||
			!item.output._data.variations
		) {
			return null;
		}

		return convertVariations.toVariation(item.output.variables, item.output._data.variations);
	}, [item.output]);

	const discount = useMemo(() => {
		if (variation) {
			return variation.discount;
		}

		return item.output?._data.discount;
	}, [variation, item.output]);

	const isEditable =
		item.outputDeal ||
		(item.output &&
			(item.output._data.type === 'variation_extras' ||
				item.output._data.type === 'simple_extras'));

	const isPartyPlatter =
		(item.output && PRODUCTS_WITH_PRE_ORDER.includes(item.output._data._id)) ||
		(item.outputSplit && PRODUCTS_WITH_PRE_ORDER.includes(item.outputSplit._data._id));

	const variablesInfo =
		item.output?.variables.map((variable) => {
			const variableData = item.output?._data.variables.find(
				(variableData) => variableData.variable._id === variable.variable,
			);
			const attributeData = variableData?.attributes.find(
				(attributeData) => attributeData.attribute._id === variable.attribute,
			);

			return {
				name: variableData?.variable.name || '',
				value: attributeData?.attribute.name || '',
			};
		}) || [];

	const partyPlatterInfo = [...variablesInfo, ...[]];

	return (
		<div className="flex-cs w-full gap-4  ">
			{/* Image of the item */}
			<Image
				src={
					item.outputDeal?.items
						? `${item.outputDeal._data.images[0].url}`
						: `${item.output?._data.images[0].url}`
				}
				className="max-h-16 max-w-16 object-contain"
				width={64}
				height={64}
				alt={''}
			/>
			{/* Main section */}
			<div className={`flex-cc col flex-1`}>
				{/* Upper section */}
				<div className={`flex-ss col mb-2 w-full`}>
					{/* MainInformation */}
					<div className={`flex-bc mb-1 w-full`}>
						<div className="flex flex-col">
							{/* Item name */}
							<p className="text-theme-light-text mb-2 mr-1 max-w-md flex-1 text-sm font-semibold uppercase lg:text-[18px]">
								{getItemName()}
							</p>
							{/* Variation text information */}
							{/* Appear if the type is productSingle */}
							{!isPartyPlatter &&
								item.output &&
								!item.outputSplit &&
								!item.outputDeal && (
									<p className="text-xs text-gray-700 lg:text-sm">
										{variation?.name ?? ''}
									</p>
								)}
							{/* Appear if the type is productSplit */}
							{item.output && item.outputSplit && !item.outputDeal && (
								<p className=" text-xs lg:text-sm ">{`${item.output?._data.name} + ${item.outputSplit?._data.name}`}</p>
							)}
						</div>

						<div className="flex-cc flex-row gap-2">
							{/* Button edit item */}
							{isEditable && (
								<button
									className="mb-0.5 w-full font-medium text-theme-green"
									onClick={handleEdit}
								>
									{/* Edit */}
									<EditOrderIcon color="#000000" />
								</button>
							)}
							{/* Button delete item */}
							<button onClick={handleDelete}>
								<TrashIcon />
							</button>
						</div>
					</div>

					{/* Extras information section */}
					{!isPartyPlatter &&
						item.output &&
						!item.outputSplit &&
						!item.outputDeal &&
						extras &&
						extras?.length > 0 && (
							<button
								className="mt-2 inline-block w-full rounded-base bg-gray-50 px-4 py-3"
								onClick={handleEdit}
							>
								{/* Customize text section */}
								<div className="flex-bc mb-2 flex w-full">
									<p className="text-xs font-medium lg:text-sm">Sérsníða</p>
									<FiChevronRight />
								</div>

								{/* Extras text information */}
								<div className="flex-ss flex-wrap gap-x-2 gap-y-0">
									{extras?.map((extra, index) => (
										<p className="inline-block text-xs lg:text-sm" key={index}>
											{extra}
										</p>
									))}
								</div>
							</button>
						)}

					{/* Deal Information Section */}
					{!isPartyPlatter && item.outputDeal?.items && (
						<Disclosure
							as="div"
							defaultOpen
							className="mt-2 flex w-full flex-col rounded-base bg-gray-50 text-xs sm:text-sm"
						>
							{({ open }) => (
								<>
									<div className="flex-bc px-4 py-2 sm:py-3">
										{/* Deal product count */}
										<p className="font-medium">
											{item.outputDeal?.items.length} Valdar vörur
										</p>
										{/* Show item button */}
										<Disclosure.Button className="font-medium text-theme-green">
											{open ? 'Loka' : 'Sjá vörur'}
										</Disclosure.Button>
									</div>
									{/* Items details panel */}
									<Transition
										enter="transition duration-200 ease-out"
										enterFrom="transform scale-95 opacity-0"
										enterTo="transform scale-100 opacity-100"
										leave="transition duration-100 ease-out"
										leaveFrom="transform scale-150 opacity-100"
										leaveTo="transform scale-95 opacity-0"
									>
										<Disclosure.Panel className="mt-1 border-t sm:mt-2">
											<div className="px-4 py-2 sm:py-3">
												{item.outputDeal?.items?.map((dealItem, i) => (
													<div
														className={`flex flex-row gap-2 py-2 ${i == 0 ? 'pt-0' : 'border-t pb-0'}`}
														key={i}
													>
														<div className="flex">{`${dealItem.quantity}x`}</div>
														<div className="flex flex-col">
															<p className="font-medium">
																{dealItem.output._data.name}
															</p>
															{/* Text information inside deal */}
															{/* Text for productSingle */}
															{!dealItem.outputSplit && (
																<p className="text-xs lg:text-sm">
																	{convertVariations.toVariation(
																		dealItem.output.variables,
																		dealItem.output._data
																			.variations,
																	)?.name ?? ''}
																</p>
															)}
															{/* Text for productSplit */}
															{dealItem.outputSplit && (
																<p className="text-xs text-gray-700 lg:text-sm">{`${dealItem.output._data.name} + ${dealItem.outputSplit?._data.name}`}</p>
															)}

															{/* Extra inside deals */}
															{!dealItem.outputSplit && (
																<div className="inline-block">
																	{getExtrasText(
																		dealItem.output,
																	)?.map(
																		(extra, index, array) => (
																			<span
																				className="text-xs text-gray-700 lg:text-sm"
																				key={index}
																			>
																				{extra}
																				{index <
																					array.length -
																						1 && ' '}
																			</span>
																		),
																	)}
																</div>
															)}
														</div>
													</div>
												))}
											</div>
										</Disclosure.Panel>
									</Transition>
								</>
							)}
						</Disclosure>
					)}

					{/* Party Platter info */}
					{isPartyPlatter && (
						<div className="mt-2 inline-block w-full rounded-base bg-gray-50 px-4 py-3">
							{partyPlatterInfo.map((partyPlatterInfo, i) => (
								<div className="flex-ss col w-full" key={i}>
									{i !== 0 && (
										<div className="my-3 h-px w-full bg-gray-200"></div>
									)}
									<p className="text-lg font-medium">{partyPlatterInfo.name}</p>
									<p className="text-sm">{partyPlatterInfo.value}</p>
								</div>
							))}
							<div className="flex-ss col w-full">
								<div className="my-3 h-px w-full bg-gray-200"></div>
								<p className="text-lg font-medium">Extras</p>
								<div className="flex-ss flex-wrap gap-x-2 gap-y-0">
									{extras?.map((extra, index) => (
										<p className="inline-block text-xs lg:text-sm" key={index}>
											{extra}
										</p>
									))}
								</div>
							</div>
						</div>
					)}
				</div>
				{/* Below section */}
				<div className={`flex-bc mt-2 w-full`}>
					<div className={`flex flex-row items-center gap-3`}>
						{/* Decrease */}
						<ButtonUpdateQuantity
							Icon={FiMinus}
							action={handleDecrease}
							isDisabled={item.quantity === 1}
						/>
						{/* The quantity */}
						<p className="text-theme-light-text min-w-[20px] text-center text-lg font-medium uppercase">
							{item.quantity}
						</p>

						{/* Increase */}
						<ButtonUpdateQuantity
							Icon={FiPlus}
							action={handleIncrease}
							isDisabled={false}
						/>
					</div>
					<div className="flex-sc gap-2">
						{price.price.final !== price.price.previous && (
							<div className="flex-sc gap-1">
								<p className="text-xs text-gray-700 line-through md:text-sm">
									{toCurrency(price.price.previous)}
								</p>

								{discount && discount.type === 'percent' && (
									<div className="rounded bg-red-50 px-1 py-0.5 text-xs text-theme-red md:text-sm">
										-{discount.amount}%
									</div>
								)}
							</div>
						)}
						<p className="text-base font-medium text-black lg:text-lg">
							{toCurrency(price.price.final)}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

// props ButtonUpdateQuantity
interface ButtonUpdateQuantityProps {
	Icon: IconType; // Define the Icon as a prop type
	action: VoidFunction;
	isDisabled: boolean;
}

// Button for increase and decrease the quantity, reusable
export const ButtonUpdateQuantity: React.FC<ButtonUpdateQuantityProps> = ({
	Icon,
	action,
	isDisabled,
}) => {
	const screenSize = useResize();

	return (
		<button
			onClick={action}
			className={`flex-cc h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-gray-200 bg-white text-black lg:h-[36px] lg:w-[36px] ${isDisabled ? 'text-gray-200' : 'hover:border-black'}`}
		>
			<Icon size={screenSize.width < 1024 ? 20 : 24} />
		</button>
	);
};
export default CartCard;
